import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Input, Upload, Button, Divider, Form } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import ApiService from '@services/apiService';

interface ObjectiveMediaFormProps {
  media: ObjectiveMedia[];
  setObjectiveMedia: any;
}

const ObjectiveMediaForm: React.FC<ObjectiveMediaFormProps> = ({
  media,
  setObjectiveMedia,
}) => {
  const handleAddObjectiveMedia = () => {
    setObjectiveMedia([
      ...media,
      {
        id: -1,
        goals: [],
      },
    ]);
  };

  const handleRemoveObjectiveMedia = (index: number) => {
    const newData = media;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setObjectiveMedia([...newData]);
  };

  const onUploadChange = (
    { fileList, file }: UploadChangeParam,
    index: number,
  ) => {
    if (fileList.length > 1) {
      fileList.splice(0, 1);
    }

    if (file.percent === 100) {
      const newData = media;
      if (newData[index]) {
        newData[index].file = file.response;
      }

      setObjectiveMedia([...newData]);
    }
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <h3>Objective Media</h3>
      {media.map((mediaItem, index) => (
        <>
          <Form.Item name={`media[${index}].id`} initialValue={mediaItem.id}>
            <Input hidden />
          </Form.Item>
          <Form.Item
            name={`media[${index}].time`}
            label="Time (in minutes)"
            initialValue={mediaItem.time}
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
          >
            <Input type="number" min={1} />
          </Form.Item>
          <Form.Item
            name={`media[${index}].description`}
            label="Description"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
            initialValue={mediaItem.description}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name={`media[${index}].url`}
            label="Url"
            initialValue={mediaItem.url}
          >
            <Input />
          </Form.Item>
          <h4>OR</h4>
          <Form.Item
            name={`media[${index}].file`}
            label="File"
            initialValue={
              mediaItem.file
                ? [
                    {
                      uid: mediaItem.file.id,
                      name: mediaItem.file.pid || mediaItem.file.url,
                      status: 'done',
                      ...mediaItem.file,
                    },
                  ]
                : []
            }
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="file"
              action={ApiService.getUrl('/files')}
              headers={ApiService.getHeaders()}
              listType="picture"
              onChange={e => onUploadChange(e, index)}
            >
              <Button>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <Button danger onClick={() => handleRemoveObjectiveMedia(index)}>
            Delete media
          </Button>
          <Divider />
        </>
      ))}
      <Button type="primary" onClick={() => handleAddObjectiveMedia()}>
        Add new media
      </Button>
    </>
  );
};

export default ObjectiveMediaForm;
