import React, { FunctionComponent, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';

import UserService from '@services/userService';

const FormItem = Form.Item;

interface Props {
  form: any;
}

type ForgotPasswordFormProps = Props;

const ForgotPasswordForm: FunctionComponent<ForgotPasswordFormProps> = ({
  form,
}) => {
  const [error, setError] = useState<{ message: string }>();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<{ message: string }>();
  const { getFieldDecorator } = form;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (!err) {
        setLoading(true);
        UserService.forgotPassword(values.email)
          .then(res => {
            setResponse(res);
            setLoading(false);
          })
          .catch(err => {
            err.response &&
              err.response.data &&
              err.response.data.message &&
              setError({
                message:
                  err.response &&
                  err.response.data &&
                  err.response.data.message,
              });
            setLoading(false);
          });
      }
    });
  };

  return (
    <div className="login">
      <section className="cover cover-page">
        <div className="cover-form-container">
          <div className="cover-form-wrap z-index">
            <section className="form-v1-container">
              <h2>Forgot password?</h2>
              <p className="lead">
                Enter your email below and request reset password link.
              </p>
              {error && (
                <Alert
                  message={error.message}
                  style={{ margin: '16px 0' }}
                  type="error"
                />
              )}
              {response && (
                <Alert
                  message={response.message}
                  style={{ margin: '16px 0' }}
                  type="success"
                />
              )}
              <Form onSubmit={handleSubmit} className="form-v1">
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Please input your email!' },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={<UserOutlined style={{ fontSize: 13 }} />}
                      placeholder="Email"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-cta btn-block"
                    disabled={loading}
                    loading={loading}
                  >
                    Request password reset link
                  </Button>
                </FormItem>
              </Form>
              <p className="additional-info">
                Don't have an account yet? <Link to="/signup">Sign up</Link>
              </p>
            </section>
          </div>
        </div>
        <div className="cover-bg-img" />
      </section>
    </div>
  );
};

const WrappedNormalForgotPasswordForm = Form.create()(ForgotPasswordForm);

export default () => (
  <div>
    <WrappedNormalForgotPasswordForm />
  </div>
);
