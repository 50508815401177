import React, { useEffect } from 'react';
import { Input, Button, Divider, Row, Col, Checkbox, Form } from 'antd';

interface AssessmentFormProps {
  assessments: Assessment[];
  setAssessments: any;
  setFieldsValue: any;
  assessmentsAILessons: [
    {
      question: '';
      answers: [
        {
          text: '';
          correct: false;
        },
      ];
    },
  ];
}

const AssessmentForm: React.FC<AssessmentFormProps> = ({
  assessments,
  setAssessments,
  setFieldsValue,
  assessmentsAILessons,
}) => {
  useEffect(() => {
    if (assessmentsAILessons && assessmentsAILessons.length > 1) {
      createItem();
    }
  }, [assessmentsAILessons]);
  const createItem = () => {
    const sroreList: {
      id: number;
      type: string;
      question: string;
      answers: { id: number; correct: boolean; text: string }[];
    }[] = [];
    if (assessmentsAILessons.length > 0) {
      assessmentsAILessons.map((AItopic: any) => {
        // const arrWithId = AItopic.answers.map((object: any) => {
        //   return { ...object, id: -1 };
        // });

        sroreList.push({
          id: -1,
          type: 'multiChoice',
          question: AItopic.question,
          // answers: arrWithId,
          answers: AItopic.answers,
        });
      });
      // console.log('sroreList', sroreList);
      // setAssessments(sroreList);
      setAssessments([...assessments, ...sroreList]);
    }
  };
  const handleAddAssessment = () => {
    setAssessments([
      ...assessments,
      {
        id: -1,
        type: 'multiChoice',
        answers: [
          {
            id: -1,
            correct: false,
            text: '',
          },
        ],
      },
    ]);
  };

  const handleRemoveAssessment = (index: number) => {
    const newData = assessments;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setAssessments([...newData]);
  };

  const handleAddAnswer = (assessmentIndex: number) => {
    const newData = assessments;
    if (newData[assessmentIndex]) {
      newData[assessmentIndex].answers.push({
        id: -1,
        correct: false,
        text: '',
      });
    }

    setAssessments([...newData]);
  };

  const handleRemoveAnswer = (assessmentIndex: number, answerIndex: number) => {
    const newData = assessments;
    if (
      newData[assessmentIndex] &&
      newData[assessmentIndex].answers[answerIndex]
    ) {
      newData[assessmentIndex].answers.splice(answerIndex, 1);
    }

    setAssessments([...newData]);
  };

  const setCorrectAnswer = (
    assessmentIndex: number,
    answerIndex: number,
    e: any,
  ) => {
    const newData = assessments;
    if (
      newData[assessmentIndex] &&
      newData[assessmentIndex].answers[answerIndex]
    ) {
      newData[assessmentIndex].answers = newData[assessmentIndex].answers.map(
        answer => ({
          ...answer,
          correct: false,
        }),
      );

      const resetAnswerFields: any = {};

      newData[assessmentIndex].answers.forEach((answer, index) => {
        resetAnswerFields[
          `assessments[${assessmentIndex}].answers[${index}].correct`
        ] = false;
      });

      setFieldsValue(resetAnswerFields);

      setFieldsValue({
        [`assessments[${assessmentIndex}].answers[${answerIndex}].correct`]:
          e.target.checked,
      });

      newData[assessmentIndex].answers[answerIndex].correct = e.target.checked;
    }

    setAssessments([...newData]);
  };

  return (
    <>
      <h3>Objective assessments</h3>
      {assessments.map((assessment, index) => (
        <>
          {assessment.id !== -1 && (
            <Form.Item
              name={`assessments[${index}].id`}
              initialValue={assessment.id}
              hidden
            >
              <Input hidden />
            </Form.Item>
          )}
          <Form.Item
            name={`assessments[${index}].question`}
            label="Question"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
            initialValue={assessment.question}
          >
            <Input.TextArea rows={2} />
          </Form.Item>

          <h4>Answers</h4>

          {assessment.answers.map((answer, answerIndex) => (
            <>
              <Form.Item
                name={`assessments[${index}].answers[${answerIndex}].id`}
                initialValue={answer.id}
                hidden
              >
                <Input hidden />
              </Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    initialValue={answer.text}
                    name={`assessments[${index}].answers[${answerIndex}].text`}
                    label="Answer text"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={`assessments[${index}].answers[${answerIndex}].correct`}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                    ]}
                    initialValue={answer.correct}
                  >
                    <Checkbox
                      onChange={e => setCorrectAnswer(index, answerIndex, e)}
                      checked={answer.correct}
                    >
                      Correct
                    </Checkbox>
                  </Form.Item>
                  <Button
                    danger
                    size="small"
                    ghost
                    onClick={() => handleRemoveAnswer(index, answerIndex)}
                  >
                    Delete answer
                  </Button>
                </Col>
              </Row>
            </>
          ))}

          <Button
            type="primary"
            size="small"
            ghost
            onClick={() => handleAddAnswer(index)}
          >
            Add answer
          </Button>
          <br />
          <br />
          <Button
            danger
            size="small"
            ghost
            onClick={() => handleRemoveAssessment(index)}
          >
            Delete assessment
          </Button>
          <Divider />
        </>
      ))}
      <Button type="primary" onClick={() => handleAddAssessment()}>
        Add new assessment
      </Button>
    </>
  );
};

export default AssessmentForm;
