import React, { ReactNode, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Divider,
  Progress,
  Button,
  Tag,
  PageHeader,
  Tabs,
} from 'antd';
import { AppState } from '@redux/reducers';
import { connect } from 'react-redux';
import { useRouter } from '@utils/routerHooks';
import Projects from '@components/projects/Projects';
import './SingleDegree.less';
import YouTubeEmbed from 'react-youtube-embed';
import {
  isUserEnrolled,
  getDegreeCompletedPercentage,
  getCourseCompletedPercentage,
} from '@utils/helpers';
import Goals from '@components/goals/Goals';
import CourseList from '@components/coursesList/CourseList';
import SubscribeMessage from '@components/subscribeMessage/SubscribeMessage';
import { enrollDegree, getCurrentUser } from '@redux/reducers/user';
import { isUserSubscribed, isUserInstructor } from '@helpers';
import Discussions from '@components/discussions/Discussions';
import withDegree from '@hoc/withDegree';
import InstructorList from '@components/instructors/InstructorList';
import VisibilityToggle from '@components/visibilityToggle/VisibilityToggle';
import StudentList from '@components/students/StudentList';
import { DegreeProgress } from '@components/degreeProgress/DegreeProgress';

const { TabPane } = Tabs;

interface DegreeProps {
  user?: User;
  degree: Degree;
  enrollDegree: typeof enrollDegree;
}

const Degree: React.FC<DegreeProps> = ({ user, degree, enrollDegree }) => {
  const { history } = useRouter();
  const [loading, setLoading] = useState(false);
  const [assignCourses, setAssignCourses] = useState<any>(0);
  const [passedCoursesCount, setPassedCoursesCount] = useState<any>(0);
  const [startCoursesCount, setStartCoursesCount] = useState<any>(0);
  const [notStartCoursesCount, setNotStartCoursesCount] = useState<any>(0);

  useEffect(() => {
    // getCurrentUser();
    if (user && !isUserInstructor(user) && user.userCourses.length > 0) {
      const completedCourses = user.userCourses.filter(
        (c: any) => c.passed == true,
      );
      setPassedCoursesCount(completedCourses.length);
      const startCount = user.userCourses.filter(
        (c: any) => c.startCourse === 'started',
      ).length;
      setStartCoursesCount(startCount);
      if (degree && degree.courses && degree.courses.length > 0) {
        const totalNotStat =
          degree.courses.length - (passedCoursesCount + startCount);
        setNotStartCoursesCount(totalNotStat);
      }
      // const rows = [];
      // for (let i = 0; i < user.userCourses.length; i++) {
      //   // const percentage = getCourseCompletedPercentage(
      //   //   user.userCourses[i],
      //   //   user,
      //   // );
      //   console.log('aaaaaaaaa', percentage, user.userCourses[i]);
      //   //   if (user.userCourses[i].passed == false) {
      //   //     if (user.userCourses[i].startCourse == 'started') {
      //   //       console.log('bbbbbbbbbbbb', user.userCourses[i]);
      //   //       //rows.push();
      //   //     }
      //   //     else if(){

      //   //     }
      //   //   }
      // }
    }
  }, [degree, loading]);

  if (!user) {
    return null;
  }

  let extra: ReactNode[] = [];

  if (isUserInstructor(user)) {
    extra = [
      <Button
        key="edit"
        onClick={() => history.push(`/degrees/${degree.id}/edit`)}
      >
        Edit
      </Button>,
    ];
  }

  return (
    <Row gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
      <Col xs={24} sm={24} md={16}>
        <PageHeader
          style={{
            background: '#fff',
            padding: '16px 16px 0',
          }}
          extra={extra}
          title={
            <>
              <Tag color="gold" style={{ fontWeight: 400 }}>
                Degree
              </Tag>
              {degree.visibility === 'private' && (
                <Tag color="orange" style={{ fontWeight: 400 }}>
                  Private
                </Tag>
              )}
              {degree.visibility === 'comingSoon' && (
                <Tag color="#2db7f5" style={{ fontWeight: 400 }}>
                  Coming Soon
                </Tag>
              )}
              <h2 style={{ margin: '8px 0' }}>{degree.name}</h2>
            </>
          }
        />
        <Tabs
          defaultActiveKey="1"
          style={{ background: '#fff', padding: '8px 16px' }}
        >
          <TabPane tab="Details" key="1">
            {degree.introVideo && <YouTubeEmbed id={degree.introVideo} />}
            <div style={{ marginTop: '16px' }}>
              <div>
                <h3>Short description</h3>
                <p>{degree.shortDescription}</p>
              </div>
              <Divider dashed />
              {degree.longDescription && (
                <div>
                  <h3>Long description</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: degree.longDescription,
                    }}
                  />
                </div>
              )}
              {degree.goals && (
                <div>
                  <Divider dashed />
                  <h3>Aims</h3>
                  <Goals goals={degree.goals} />
                </div>
              )}
              {degree.courses && (
                <div>
                  <Divider dashed />
                  <h3>Courses</h3>
                  <CourseList
                    degree={degree}
                    courses={degree.courses}
                    assignCourses={assignCourses}
                    passedCoursesCount={passedCoursesCount}
                    startCoursesCount={startCoursesCount}
                    notStartCoursesCount={notStartCoursesCount}
                  />
                </div>
              )}
            </div>
          </TabPane>
          {/* <TabPane tab="Prerequisites" key="4">
            No prerequisites.
          </TabPane> */}
          <TabPane tab="Capstone Project" key="2">
            <Projects projects={degree.projects} />
          </TabPane>
          <TabPane tab="Discussions" key="3">
            <Discussions degreeId={degree.id} />
          </TabPane>
          {isUserInstructor(user) && (
            <TabPane tab="Admin" key="admin">
              <VisibilityToggle
                item={degree}
                type="degree"
                onChange={(item: any) => {
                  history.go(0);
                }}
              />
              <br />
              <InstructorList
                user={user}
                mainInstructorId=""
                type="degree"
                itemId={degree.id}
              />
            </TabPane>
          )}
        </Tabs>
      </Col>
      <Col xs={24} sm={24} md={8}>
        {isUserInstructor(user) && (
          <Card size="small" title="Students" className="sidebar-widget">
            <StudentList id={degree.id} type="degree" />
          </Card>
        )}
        {!isUserInstructor(user) &&
          !isUserEnrolled('degree', degree.id, user) && (
            <Card className="sidebar-widget">
              <Button
                type="primary"
                size="large"
                block
                disabled={!isUserSubscribed(user)}
                onClick={() => {
                  enrollDegree(degree.id);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }}
              >
                Enroll
              </Button>
              <SubscribeMessage user={user} />
            </Card>
          )}
        {!isUserInstructor(user) &&
          isUserEnrolled('degree', degree.id, user) && (
            <Card size="small" title="Progress" className="sidebar-widget">
              <DegreeProgress degreeId={degree.id} user={user} />
            </Card>
          )}
      </Col>
    </Row>
  );
};

const selectDegreeState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedDegree = withDegree(
  connect(selectDegreeState, { enrollDegree })(Degree),
);

const Page = () => <ConnectedDegree />;

export default Page;
