import React, { useMemo, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Upload, Button, Divider, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import ApiService from '@services/apiService';
import { getFileUrl, isUserInstructor, isUserAdmin } from '@helpers';
// import GoalsForm from './GoalsForm';
import FilePreview from '@components/files/FilePreview';
import ReadingService from '@services/readingService';
import { toast } from 'react-toastify';

interface ReadingsFormProps {
  readings: Reading[];
  setReadings: any;
  getFieldDecorator: any;
}

const ReadingsForm: React.FC<ReadingsFormProps> = ({
  readings,
  setReadings,
  getFieldDecorator,
}) => {
  const handleAddReading = () => {
    setReadings([
      ...readings,
      {
        id: -1,
        // goals: [],
        files: [],
      },
    ]);
  };
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [readingId, setReadingId] = useState<any>();
  const [fileId, setFileId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const fileDeleteReading = () => {
    setLoading(true);
    const aaa = ReadingService.deleteFileReading(readingId, fileId)
      .then(d => {
        setDeleteModalVisible(false);
        toast.success('Delete file successful!');
        // //history.push(`/lessons/${d.id}`);
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        console.log('err', err);
        // toast.error(err.message);
      });
  };

  const handleRemoveReading = (index: number) => {
    const newData = readings;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setReadings([...newData]);
  };

  // const handleUpdateGoals = (index: number) => (goals: Goal[]) => {
  //   readings[index].goals = goals;
  //   setReadings([...readings]);
  // };

  const onUploadChange = (
    { fileList, file }: UploadChangeParam,
    index: number,
  ) => {
    if (file.percent === 100) {
      const fileObject = file.response;
      const newData = readings;

      if (newData[index] && fileObject) {
        newData[index].files.push(fileObject);
      }

      setReadings([...newData]);
    }
  };

  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpdateDescription = (value: string, index: number) => {
    readings[index].description = value;
    setReadings([...readings]);
  };

  const handleUpdateExmapleFileUrl = (value: string, index: number) => {
    readings[index].exampleFileUrl = value;
    setReadings([...readings]);
  };

  const handleUpdateName = (value: string, index: number) => {
    readings[index].name = value;
    setReadings([...readings]);
  };
  const handleUpdateTime = (value: string, index: number) => {
    readings[index].time = value;
    setReadings([...readings]);
  };

  const loginUserRole = localStorage.getItem('loginUserRole');
  return null;
  // return (
  //   <>
  //     {readings.map((readin, index) => (
  //       <>
  //         {getFieldDecorator(`readings[${index}].id`, {
  //           initialValue: readin.id,
  //         })(<Input hidden />)}

  //         <Form.Item label="Name">
  //           {getFieldDecorator(`readings[${index}].name`, {
  //             rules: [
  //               {
  //                 required: true,
  //                 message: 'This field is required!',
  //               },
  //             ],
  //             initialValue: readin.name,
  //           })(
  //             <Input onChange={e => handleUpdateName(e.target.value, index)} />,
  //           )}
  //         </Form.Item>

  //         <Form.Item label="Time (in minutes)">
  //           {getFieldDecorator(`readings[${index}].time`, {
  //             initialValue: readin.time,
  //             rules: [
  //               {
  //                 required: true,
  //                 message: 'This field is required!',
  //               },
  //             ],
  //           })(
  //             <Input
  //               type="number"
  //               min={1}
  //               onChange={e => handleUpdateTime(e.target.value, index)}
  //             />,
  //           )}
  //         </Form.Item>

  //         <Form.Item label="Description">
  //           {getFieldDecorator(`readings[${index}].description`, {
  //             rules: [
  //               {
  //                 required: true,
  //                 message: 'This field is required!',
  //               },
  //             ],
  //             initialValue: readin.description,
  //           })(
  //             <Input.TextArea
  //               rows={7}
  //               onChange={e => handleUpdateDescription(e.target.value, index)}
  //             />,
  //           )}
  //         </Form.Item>

  //         {/* <GoalsForm
  //         goals={readin.goals}
  //         onUpdate={handleUpdateGoals(index)}
  //       /> */}

  //         <Form.Item label="Example file url">
  //           {getFieldDecorator(`readings[${index}].exampleFileUrl`, {
  //             initialValue: readin.exampleFileUrl,
  //           })(
  //             <Input
  //               onChange={e =>
  //                 handleUpdateExmapleFileUrl(e.target.value, index)
  //               }
  //             />,
  //           )}
  //         </Form.Item>
  //         <Form.Item label="Example file">
  //           {getFieldDecorator(`readings[${index}].files]`, {
  //             valuePropName: 'fileList',
  //             getValueFromEvent: normFile,
  //             initialValue: readin.files
  //               ? readin.files.map(file => ({
  //                   uid: file.id,
  //                   name: file.pid || file.url,
  //                   status: 'done',
  //                   ...file,
  //                 }))
  //               : [],
  //           })(
  //             <>
  //               <Upload
  //                 name="file"
  //                 action={ApiService.getUrl('/files')}
  //                 headers={ApiService.getHeaders()}
  //                 listType="picture"
  //                 onChange={e => onUploadChange(e, index)}
  //               >
  //                 <Button>
  //                   <UploadOutlined /> Click to upload
  //                 </Button>
  //               </Upload>
  //             </>,
  //           )}
  //         </Form.Item>
  //         <span>
  //           {readin.files.map((file: any) => (
  //             <>
  //               {loginUserRole == 'admin' && (
  //                 <div style={{ textAlign: 'end', marginBottom: '8px' }}>
  //                   <Button
  //                     type="danger"
  //                     onClick={() => {
  //                       setDeleteModalVisible(true);
  //                       setReadingId(readin.id);
  //                       setFileId(file.id);
  //                     }}
  //                   >
  //                     Delete
  //                   </Button>
  //                 </div>
  //               )}
  //               <FilePreview file={file} />
  //             </>
  //           ))}
  //         </span>
  //         <Button type="danger" onClick={() => handleRemoveReading(index)}>
  //           Delete
  //         </Button>
  //         <Divider />
  //       </>
  //     ))}
  //     <Button type="primary" onClick={() => handleAddReading()}>
  //       Add new
  //     </Button>
  //     <Modal
  //       title="Are you sure you want to delete file?"
  //       width={550}
  //       visible={deleteModalVisible}
  //       okText="Delete file"
  //       okButtonProps={{ loading, type: 'danger' }}
  //       onOk={() => fileDeleteReading()}
  //       onCancel={() => setDeleteModalVisible(false)}
  //     >
  //       <p>You will not be able to revert this action</p>
  //     </Modal>
  //   </>
  // );
};

export default ReadingsForm;
