import openSocket from 'socket.io-client';

const connectToSockets = (
  fetchVideoCalls: any,
  fetchScheduledCalls: any,
  addNotification: any,
) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    return false;
  }

  const config = {
    // transportOptions: {
    //   polling: {
    //     extraHeaders: { Authorization: `Bearer ${token}` },
    //   },
    // },
    path: '/',
    query: {
      token,
    },
  };

  if (
    process.env.REACT_APP_SOCKETS_URL &&
    process.env.REACT_APP_SOCKETS_URL.indexOf('stage.teachur.co') !== -1
  ) {
    // debugger;
    config.path = '/api/socket.io';
  } else {
    config.path = '/socket.io';
  }

  const socket = openSocket(
    process.env.REACT_APP_SOCKETS_URL || 'localhost:3000',
    config,
  );

  socket.on('newNotification', (notification: Notification) => {
    addNotification(notification);
  });

  socket.on('newVideoCall', () => {
    fetchVideoCalls();
  });

  socket.on('updatedScheduledCall', () => {
    fetchScheduledCalls();
  });

  socket.on('reconnect_attempt', () => {
    // debugger;
    socket.io.opts.transports = ['polling', 'websocket'];
  });
};

export default connectToSockets;
