import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';

type ReorderFunc = <T extends unknown>(
  list: T[],
  startIndex: number,
  endIndex: number,
) => T[];

const reorder: ReorderFunc = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const urlMap = {
  objective: '/objectives',
  lesson: '/lessons',
  course: '/courses',
  degree: '/degrees',
};

const getUrl = (id: number, type: keyof typeof urlMap) =>
  `${urlMap[type] as string}/${id}/edit`;

const draggableStyle = {
  border: '1px solid #aaa',
  background: '#eee',
  marginTop: 4,
  paddingLeft: 8,
};

interface IDraggableList<T> {
  items: T[];
  setItems: any;
  type: 'objective' | 'lesson' | 'course' | 'degree';
}

type DraggableListType<T = any> = React.FC<IDraggableList<T>>;

const DraggableList: DraggableListType = ({ items, setItems, type }) => {
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder<Course>(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(reorderedItems);
  };

  const handleRemoveItem = (index: number) => {
    const newData = items;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setItems([...newData]);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <p style={{ margin: '5px 0', color: '#bbb' }}>
        Drag and drop items to reorder them
      </p>
      <Droppable droppableId="courses">
        {props => (
          <div ref={props.innerRef} {...props.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div style={draggableStyle}>
                      {index + 1}. {item.name}(ID: {item.id})
                      <Button
                        danger
                        size="small"
                        style={{
                          marginTop: 6,
                          marginRight: 8,
                          float: 'right',
                        }}
                        onClick={() => handleRemoveItem(index)}
                      >
                        <DeleteOutlined />
                      </Button>
                      <Button
                        size="small"
                        style={{
                          marginTop: 6,
                          marginRight: 8,
                          float: 'right',
                        }}
                      >
                        <a
                          href={getUrl(item.id, type as keyof typeof urlMap)}
                          target="_blank"
                          style={{
                            float: 'right',
                          }}
                          rel="noreferrer"
                        >
                          <LinkOutlined />
                        </a>
                      </Button>
                      {/* <Button
                        className="ai-comman-btn-pulse"
                        size="small"
                        style={{
                          marginTop: 6,
                          marginRight: 8,
                          float: 'right',
                          background: ' #eeeeee',
                          borderColor: ' #eeeeee',
                        }}
                      >
                        <Icon style={{ color: '#46a5e5' }} type="plus" />
                      </Button> */}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
