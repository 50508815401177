import React, { FunctionComponent, useState } from 'react';
import ReactQuill from 'react-quill';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Input,
  Button,
  PageHeader,
  Row,
  Col,
  Upload,
  Divider,
  Form,
} from 'antd';
import { toast } from 'react-toastify';
import CourseService from '@services/courseService';
import { useHistory } from 'react-router';
import GoalsForm from '@components/forms/GoalsForm';
import ChildrenItemsForm from '@components/forms/ChildrenItemsForm';
import ProjectsForm from '@components/forms/ProjectsForm';
import { normalizeProjects } from '@components/forms/helpers';
import ApiService from '@services/apiService';
import { UploadChangeParam } from 'antd/lib/upload';
import AIIcon from '@assets/AI_with_notes.webp';

interface CourseFormProps {
  course: Partial<Course>;
}

const CourseForm: FunctionComponent<CourseFormProps> = ({ course }) => {
  const history = useHistory();
  const [goals, setGoals] = useState<Goal[]>(course.goals || []);
  const [connections, setConnections] = useState<Course[]>(
    course.connections || [],
  );
  const [updateLoading, setUpdateLoading] = useState(false);
  const [syllabusLoading, setSyllabusLoading] = useState(false);
  const [shortDesLoading, setShortDesLoading] = useState(false);
  const [longDesLoading, setLongDesLoading] = useState(false);
  const [lessons, setLessons] = useState<Lesson[]>(course.lessons || []);
  const [projects, setProjects] = useState<Project[]>(course.projects || []);

  const [courseName, setCourseName] = useState(course.name);
  // const aa = localStorage.setItem('courseName', (courseName));

  const [courseInfoAI, setCourseInfoAI] = useState('');
  const [shortDescription, setShortDescription] = useState(
    course.shortDescription,
  );
  const [longDescription, setLongDescription] = useState(
    course.longDescription,
  );

  const [image, setImage] = useState(course.image);
  const [form] = Form.useForm();
  const { setFieldsValue, validateFields } = form;
  const [goalsAICourse, setGoalsAICourse] = useState<any>([]);
  const [chaptersAICourse, setChaptersAICourse] = useState<any>([]);
  const [lessonsAICourse, setLessonsAICourse] = useState<any>([]);
  const [lessonDemoAI, setLessonDemoAI] = useState<any>([]);
  const [lessonDemoAI11, setLessonDemoAI11] = useState<any>([]);
  const [topicsAILessons, setTopicsAILessons] = useState<any>({
    prompt: {
      courseName: '',
      lessonInfo: '',
      lessonName: '',
      userIdentifier: '',
    },
    topics: [],
  });
  const [showCourseKey, setShowCourseKey] = useState(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();

    validateFields().then((values: Course) => {
      if (course.id) {
        if (!values.credit || values.credit < 0) {
          values.credit = 3;
        }
        setUpdateLoading(true);

        const normalizedProjects = projects ? normalizeProjects(projects) : [];

        const savePromise =
          course.id === -1
            ? CourseService.create({
                ...values,
                longDescription,
                lessons,
                connections,
                goals: goals as Goal[],
                image,
                projects: normalizedProjects as Project[],
              })
            : CourseService.update(course.id, {
                ...values,
                longDescription,
                lessons,
                connections,
                id: course.id,
                image,
                goals: goals as Goal[],
                projects: normalizedProjects as Project[],
              });

        savePromise
          .then(d => {
            toast.success('Update successful!');
            history.push(`/courses/${d.id}`);
          })
          .catch(e => {
            // toast.error('Update failed!');
            toast.error(e.data.message);
            setUpdateLoading(false);
          });
      }
    });
  };

  const onUploadChange = ({ fileList, file }: UploadChangeParam) => {
    if (file.percent === 100) {
      setImage(file.response);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpdateGoals = (goals: Goal[]) => {
    setGoals(goals);
  };

  const hideShowCourseKey = (a: any) => {
    setShowCourseKey(!a);
  };
  const AICourseSyllabusGet = async () => {
    try {
      if (courseName == '' || courseName == undefined) {
        return;
      }
      const sendData = {
        courseName,
        courseInfo: courseInfoAI,
        userIdentifier: '',
      };
      setSyllabusLoading(true);
      const CourseSyllabus = await CourseService.getCourseSyllabusAI(sendData);
      if (CourseSyllabus.success) {
        setSyllabusLoading(false);
        setGoalsAICourse(CourseSyllabus.data.completion.goals);
        setChaptersAICourse(CourseSyllabus.data.completion.chapters);
        const sroreList: { id: number; text: string }[] = [];
        if (CourseSyllabus.data.completion.goals.length) {
          CourseSyllabus.data.completion.goals.map((item: any) => {
            sroreList.push({ id: -1, text: item });
          });
          // setGoals(sroreList);
          setGoals([...goals, ...sroreList]);
        }
        const sroreList1: { id: number; text: string }[] = [];
        if (CourseSyllabus.data.completion.chapters.length) {
          CourseSyllabus.data.completion.chapters.map((item: any) => {
            sroreList1.push({ id: -1, text: item });
          });
          setLessonsAICourse(sroreList1);
        }
      } else {
        setSyllabusLoading(false);
        // console.log('Get response else', CourseSyllabus);
      }
    } catch (err) {
      setSyllabusLoading(false);
      // console.log('Get response catch', err);
    }
  };

  const AICourseGoalsGet = (goalAI: []) => {
    const sroreList: { id: number; text: string }[] = [];
    if (goalAI.length) {
      goalAI.map((item: any) => {
        sroreList.push({ id: -1, text: item });
      });
      setGoals(sroreList);
    }
  };

  const AICourseLessionGet = (lessionAI: []) => {
    const sroreList: { id: number; text: string }[] = [];
    if (lessionAI.length) {
      lessionAI.map((item: any) => {
        sroreList.push({ id: -1, text: item });
      });
      setLessonsAICourse(sroreList);
    }
  };

  const handleRemoveLession = (index: number) => {
    const newData = lessonsAICourse;
    if (newData[index]) {
      newData.splice(index, 1);
    }
    setLessonsAICourse([...newData]);
  };
  const handleAddLession = (index: number, lession: any) => {
    if (courseName == '' || courseName == undefined) {
      return;
    }
    if (lession.text == '' || lession.text == undefined) {
      return;
    }
    setLessonDemoAI({ courseName, lessionNameAI: lession.text });
    handleRemoveLession(index);
  };
  const AICourseSortDesGet = async (course: any, goalAI: []) => {
    try {
      if (courseName == '' || courseName == undefined) {
        return;
      }
      if (goalAI.length == 0) {
        // console.log('goalAI field is required!');
        return;
      }
      // const goalAIFilter = goalAI.toString().replace(/,/g, '\n-');
      const sendData = {
        courseName,
        courseGoals: goalAI.toString().replace(/,/g, '\n-'),
        userIdentifier: '',
      };

      setShortDesLoading(true);
      const CourseSortDes = await CourseService.getCourseSortDesAI(sendData);
      if (CourseSortDes.success) {
        setShortDesLoading(false);
        // console.log('CourseSortDes', CourseSortDes.data.completion);
        setShortDescription(CourseSortDes.data.completion);
        form.setFieldsValue({
          shortDescription: CourseSortDes.data.completion,
        });
      } else {
        // console.log('Get response else', CourseSortDes);
      }
    } catch (err) {
      // toast.error('An error occured!');
    }
  };

  const AICourseLongDesGet = async (goalAI: []) => {
    try {
      if (courseName == '' || courseName == undefined) {
        return;
      }
      if (shortDescription == '' || shortDescription == undefined) {
        return;
      }
      if (goalAI.length == 0) {
        // console.log('goalAI field is required!');
        return;
      }
      const sendData = {
        courseName,
        courseGoals: goalAI.toString().replace(/,/g, '\n-'),
        userIdentifier: '',
      };
      setLongDesLoading(true);
      const CourseLongDes = await CourseService.getCourseLongDesAI(sendData);
      if (CourseLongDes.success) {
        setLongDesLoading(false);
        // console.log('CourseLongDes', CourseLongDes.data.completion[0]);
        // toast.success('Student course review updated!');
        setLongDescription(CourseLongDes.data.completion[0]);
      } else {
        // console.log('Get response else', CourseLongDes);
      }
    } catch (err) {
      // toast.error('An error occured!');
    }
  };
  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={[16, { xs: 16, sm: 16 }]}>
        <Col lg={24} md={14}>
          <PageHeader
            style={{
              background: '#fff',
              padding: '24px 16px 16px',
            }}
            extra={[
              <Button
                key="save"
                type="primary"
                loading={updateLoading}
                onClick={handleSubmit}
              >
                Save
              </Button>,
            ]}
            title={
              <>
                <h2 style={{ marginBottom: 0 }}>
                  {course.id === -1 ? 'Create ' : 'Edit '}Course
                </h2>
              </>
            }
          />
          <div
            style={{
              background: '#fff',
              padding: '16px 16px',
            }}
          >
            <Form.Item
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={course.name}
            >
              <Input
                onChange={(e: any) => {
                  setCourseName(e.target.value);
                }}
              />
            </Form.Item>
            <Button
              className="ai-btn-syllabus"
              type="primary"
              size="small"
              onClick={() => {
                hideShowCourseKey(showCourseKey);
              }}
            >
              <PlusOutlined
                style={{ color: '#46a5e5', fontSize: '22px' }}
                className="key_pluse"
              />
            </Button>
            {showCourseKey && (
              <Form.Item label="" initialValue="">
                <Input
                  onChange={(e: any) => {
                    setCourseInfoAI(e.target.value);
                  }}
                  placeholder="Enter information about your course, key words, dates, areas to be covered"
                  style={{ minHeight: '100px' }}
                />
              </Form.Item>
            )}

            <Form.Item label="Intro video" initialValue={course.introVideo}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              initialValue={
                image
                  ? {
                      uid: image.id,
                      name: image.pid || image.url,
                      status: 'done',
                      ...image,
                    }
                  : []
              }
            >
              <>
                <div>
                  {image && image.url ? (
                    <img style={{ maxWidth: 400 }} src={image.url} />
                  ) : null}
                </div>
                <Upload
                  name="file"
                  action={ApiService.getUrl('/files')}
                  headers={ApiService.getHeaders()}
                  listType="picture"
                  onChange={e => onUploadChange(e)}
                >
                  <Button>
                    <UploadOutlined /> Click to upload
                  </Button>
                </Upload>
              </>
            </Form.Item>
            <Button
              className="ai-btn-short-des"
              disabled={goalsAICourse.length == 0}
              type="primary"
              onClick={() => {
                AICourseSortDesGet(course, goalsAICourse);
              }}
              loading={shortDesLoading}
            >
              {!shortDesLoading && (
                <img
                  className={shortDesLoading ? 'AIIcon-loding' : ''}
                  style={{
                    height: '35px',
                    width: '35px',
                  }}
                  src={AIIcon}
                />
              )}
            </Button>
            <Form.Item
              label="Short description"
              initialValue={course.shortDescription}
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
            >
              <Input.TextArea
                onChange={(e: any) => {
                  setShortDescription(e.target.value);
                }}
              />
            </Form.Item>
            <div className="ant-col ant-form-item-label">
              <label
                className="ant-form-item-required"
                title="Long description"
              >
                Long description
              </label>
              <span>
                <Button
                  type="primary"
                  disabled={goalsAICourse.length == 0}
                  onClick={() => {
                    AICourseLongDesGet(goalsAICourse);
                  }}
                  loading={longDesLoading}
                  className="ai-btn-long-des"
                >
                  <img
                    style={{
                      height: '35px',
                      width: '35px',
                    }}
                    src={AIIcon}
                  />
                </Button>
              </span>
            </div>
            <ReactQuill
              value={longDescription || ''}
              onChange={(value: string) => {
                setLongDescription(value);
              }}
            />
            <Form.Item label="Credit hours" initialValue={course.credit}>
              <Input type="number" />
            </Form.Item>
            <Button
              loading={syllabusLoading}
              className="ai-btn-course-goals"
              type="primary"
            >
              {' '}
              {!syllabusLoading && (
                <img
                  style={{
                    height: '35px',
                    width: '35px',
                  }}
                  onClick={() => {
                    AICourseSyllabusGet();
                  }}
                  src={AIIcon}
                />
              )}
            </Button>
            <GoalsForm goals={goals} onUpdate={handleUpdateGoals} />
            <Button
              loading={syllabusLoading}
              className="ai-btn-course-lession"
              type="primary"
            >
              {' '}
              {!syllabusLoading && (
                <img
                  style={{
                    height: '35px',
                    width: '35px',
                  }}
                  onClick={() => {
                    AICourseSyllabusGet();
                  }}
                  src={AIIcon}
                />
              )}
            </Button>
            <ChildrenItemsForm<Lesson>
              type="lesson"
              items={lessons}
              setItems={setLessons}
              setFieldsValue={setFieldsValue}
              showCreate
              lessionAI={lessonDemoAI}
            />
            <div style={{ marginTop: '15px' }}>
              <ul>
                {lessonsAICourse.map((lession: any, index: any) => (
                  <li
                    key={`${lession.id}#${index}`}
                    style={{ marginTop: '15px' }}
                  >
                    <>
                      {lession.text}
                      <Button
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        danger
                        size="small"
                        ghost
                        onClick={() => {
                          handleRemoveLession(index);
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                      <Button
                        onClick={() => {
                          handleAddLession(index, lession);
                        }}
                        className="ai-comman-btn-pulse"
                        size="small"
                      >
                        <PlusOutlined
                          style={{ color: '#46a5e5' }}
                          className="key_pluse"
                        />
                      </Button>
                    </>
                  </li>
                ))}
              </ul>
            </div>
            <Divider dashed />
            <h2>Course Connections</h2>

            <Form.Item label="Course Code" initialValue={course.code}>
              <Input />
            </Form.Item>
            <ChildrenItemsForm<Course>
              title="Prerequisites"
              type="course"
              items={connections}
              setItems={setConnections}
              setFieldsValue={setFieldsValue}
              lessionAI={lessonDemoAI11}
            />

            <Divider dashed />
            <h2>Projects</h2>
            <ProjectsForm
              topicsAILessons={topicsAILessons}
              projects={projects}
              setProjects={setProjects}
            />
            <Divider dashed />
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={updateLoading}>
                Save
              </Button>
            </Form.Item>
          </div>
        </Col>
        {/* <Col lg={8} md={10}>
        <Card size="small" title="Projects" className="sidebar-widget">
          <ProjectsForm
            projects={projects}
            setProjects={setProjects}
            getFieldDecorator={getFieldDecorator}
          />
        </Card>
      </Col> */}
      </Row>
    </Form>
  );
};
export default CourseForm;
