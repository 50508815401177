/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Input, Upload, Button, Divider, Form } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { toast } from 'react-toastify';
import ApiService from '@services/apiService';
import UserService from '@services/userService';

interface CreditsFormProps {
  user: User;
  onSubmit: () => void;
}

export const CreditsForm: React.FC<CreditsFormProps> = ({ user, onSubmit }) => {
  const [credits, setCredits] = useState<Credit[]>([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [form] = Form.useForm<{ credits: Credit[] }>();
  const { validateFields } = form;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    validateFields().then(values => {
      setUpdateLoading(true);

      UserService.updateCredits(user.id, values.credits)
        .then(d => {
          setUpdateLoading(false);
          toast.success('Update successful!');
          onSubmit();
        })
        .catch(() => {
          toast.error('Update failed!');
          setUpdateLoading(false);
        });
    });
  };

  const handleAddCredit = () => {
    setCredits([
      ...credits,
      {
        id: -1,
        institution: '',
        amount: 0,
        userId: user.id,
      },
    ]);
  };

  const handleRemoveCredit = (index: number) => {
    const newData = credits;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setCredits([...newData]);
  };

  const onUploadChange = (
    { fileList, file }: UploadChangeParam,
    index: number,
  ) => {
    if (fileList.length > 1) {
      fileList.splice(0, 1);
    }

    if (file.percent === 100) {
      const uploadedFile = file.response;

      const newData = credits;
      if (newData[index]) {
        newData[index].file = uploadedFile;
      }

      setCredits([...newData]);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      {credits.map((credit, index) => (
        <>
          <Form.Item
            name={`credits[${index}].institution`}
            label="Institution name"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
            initialValue={credit.institution}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={`credits[${index}].amount`}
            label="Number of credits"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
            initialValue={credit.institution}
          >
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item
            label="File"
            name={`credits[${index}].file`}
            {...{
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              initialValue: credit.file
                ? [
                    {
                      ...credit.file,
                      uid: credit.file.id,
                      name: credit.file.pid || credit.file.url,
                      status: 'done',
                    },
                  ]
                : [],
            }}
          >
            <Upload
              name="file"
              action={ApiService.getUrl('/files')}
              headers={ApiService.getHeaders()}
              listType="picture"
              onChange={e => onUploadChange(e, index)}
            >
              <Button>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <Button danger onClick={() => handleRemoveCredit(index)}>
            Delete credit
          </Button>
          <Divider />
        </>
      ))}
      <Button type="primary" ghost onClick={() => handleAddCredit()}>
        Add new credit
      </Button>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={updateLoading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreditsForm;
