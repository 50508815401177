import * as React from 'react';

import {
  BookOutlined,
  CopyOutlined,
  DashboardOutlined,
  GiftOutlined,
  NotificationOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { History } from 'history';

import { isUserInstructor, isUserAdmin } from '@helpers';

import { ParsedPath } from '@components/breadcrumbs/Breadcrumbs';
import CourseService from '@services/courseService';
import { DegreeMenu } from '@components/menus/degreeMenu/DegreeMenu';
import {
  COLLAPSED_WIDTH,
  NORMAL_WIDTH,
  COLLAPSED_ON,
  NORMAL_WIDTH_ON_COLLAPSE,
} from './constants';
import {
  generateSubMenu,
  generateCourseSubMenu,
  generateCourseSubMenu11,
  generateSubMenuCoursesList,
} from './helpers';
import { isSalesPerson } from '.';

const { Sider } = Layout;
const { Item, SubMenu } = Menu;

export interface ISidebarProps {
  user: User;
  collapsed: boolean;
  openKeys: string[];
  selectedKeys: string[];
  handleSubMenuOpen: any;
  degrees: Degree[];
  pathItems: ParsedPath;
  history: History<any>;
}

const Sidebar: React.FC<ISidebarProps> = ({
  user,
  collapsed,
  openKeys,
  selectedKeys,
  handleSubMenuOpen,
  degrees,
  pathItems,
  history,
}: ISidebarProps) => {
  // const [course, setCourses] = React.useState<Course[]>([]);
  const normalWidth =
    window.innerWidth <= COLLAPSED_ON ? NORMAL_WIDTH_ON_COLLAPSE : NORMAL_WIDTH;
  const { pathname } = history.location;

  // const parts = pathname.split('/');

  // const courseId = parseInt(parts[2]); // 221
  // const lessonId = parseInt(parts[4]); // 1901
  // const objectiveId = parseInt(parts[6]); // 10195

  // const isCoursesPath = pathname === '/courses';
  // const customFunction = async () => {
  //   CourseService.getAll().then(d => {
  //     setCourses(d);
  //   });
  // };

  // console.log(user.enrolledDegrees);

  // let contentToRender;

  // if (
  //   user &&
  //   user.email !== 'contact@newlaneuniversity.com' &&
  //   user.email !== 'philosophy@newlaneuniversity.com' &&
  //   !isUserInstructor(user)
  // ) {
  //   const generateCourseSubMenuContent = () =>
  //     course.map(course =>
  //       generateSubMenuCoursesList(
  //         course,
  //         history,
  //         user,
  //         pathItems,
  //         course.lessons,
  //       ),
  //     );

  //   const generateDegreesSubMenuContent = () =>
  //     degrees.map(degree =>
  //       generateSubMenu(degree, history, user, pathItems, degree.courses),
  //     );

  //   switch (pathname) {
  //     case '/courses':
  //     case `/courses/${courseId}`:
  //     case `/courses/${courseId}/lessons/${lessonId}`:
  //     case `/courses/${courseId}/lessons/${lessonId}/objectives/${objectiveId}`:
  //       contentToRender = generateCourseSubMenuContent();
  //       break;

  //     default:
  //       contentToRender = generateDegreesSubMenuContent();
  //   }
  // }

  // React.useEffect(() => {
  //   customFunction();
  // }, []);

  return (
    <Sider
      style={{
        overflow: 'hidden auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 11,
      }}
      width={collapsed ? `${COLLAPSED_WIDTH}px` : `${normalWidth}px`}
      className={collapsed ? 'sidebar collapsed-sidebar ' : 'sidebar'}
    >
      {!isSalesPerson(user) && (
        <>
          <Menu
            theme="light"
            mode="inline"
            inlineIndent={2}
            // defaultOpenKeys={openKeys}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            // forceSubMenuRender={true}
            multiple
            onOpenChange={handleSubMenuOpen}
          >
            <Item key="/">
              <DashboardOutlined />
              <span className="nav-text">Dashboard</span>
              <Link to="/" />
            </Item>
            {user.email !== 'contact@newlaneuniversity.com' &&
              user.email !== 'philosophy@newlaneuniversity.com' && (
                <Item key="/degrees">
                  <BookOutlined />
                  <span className="nav-text">Degrees</span>
                  <Link to="/degrees" />
                </Item>
              )}
            {!isUserInstructor(user) && (
              <Item key="/courses">
                <BookOutlined />
                <span className="nav-text">Courses</span>
                <Link to="/courses" />
              </Item>
            )}
            {isUserInstructor(user) && (
              <Item key="/students">
                <TeamOutlined />
                <span className="nav-text">Students</span>
                <Link to="/students" />
              </Item>
            )}
            {isUserInstructor(user) && (
              <Item key="/objective-media-review">
                <BookOutlined />
                <span className="nav-text">Objective Media Feedback</span>
                <Link to="/objective-media-review" />
              </Item>
            )}
            {isUserInstructor(user) && (
              <Item key="/announcements">
                <NotificationOutlined />
                <span className="nav-text">Announcements</span>
                <Link to="/announcements" />
              </Item>
            )}
            {isUserAdmin(user) && (
              <SubMenu
                key="/admin"
                className="normal-menu"
                title={
                  <div>
                    <SettingOutlined /> Administration
                  </div>
                }
              >
                <Item key="/admin/users">
                  <UserOutlined />
                  <span className="nav-text">Users</span>
                  <Link to="/admin/users" />
                </Item>
                <Item key="/admin/coupons">
                  <GiftOutlined />
                  <span className="nav-text">Coupons</span>
                  <Link to="/admin/coupons" />
                </Item>
                <Item key="/admin/agreements">
                  <CopyOutlined />
                  <span className="nav-text">Agreements</span>
                  <Link to="/admin/agreements" />
                </Item>
              </SubMenu>
            )}
            {/* {!collapsed && !isUserInstructor(user) && <Divider />}
          {!collapsed &&
            !isUserInstructor(user) &&
            user.email !== 'contact@newlaneuniversity.com' &&
            user.email !== 'philosophy@newlaneuniversity.com' && (
              <Item disabled={true}>
                <span className="nav-text">
                  Enrolled{' '}
                  {user.enrolledDegrees && user.enrolledDegrees.length > 0
                    ? 'degrees'
                    : 'courses'}
                  :
                </span>
              </Item>
            )} */}

            {!collapsed && !isUserInstructor(user) && <Divider />}
            {/* {!collapsed &&
              !isUserInstructor(user) &&
              user.email !== 'contact@newlaneuniversity.com' &&
              user.email !== 'philosophy@newlaneuniversity.com' && (
                <Item disabled>
                  <span className="nav-text">
                    Enrolled{' '}
                    {user.enrolledDegrees &&
                    user.enrolledDegrees.length > 0 &&
                    (pathname === '/courses' ||
                      pathname === `/courses/${courseId}` ||
                      pathname === `/courses/${courseId}/lessons/${lessonId}` ||
                      pathname ===
                        `/courses/${courseId}/lessons/${lessonId}/objectives/${objectiveId}`)
                      ? 'courses'
                      : 'degrees'}
                    :
                  </span>
                </Item>
              )} */}
            {user &&
              user.email !== 'contact@newlaneuniversity.com' &&
              user.email !== 'philosophy@newlaneuniversity.com' &&
              !collapsed &&
              !isUserInstructor(user) &&
              (!user.enrolledDegrees || !user.enrolledDegrees.length) &&
              user.enrolledCourses &&
              !user.enrolledCourses.length && (
                <p style={{ textAlign: 'center' }}>No enrolled degrees.</p>
              )}
            {/* {user &&
            user.email !== 'contact@newlaneuniversity.com' &&
            user.email !== 'philosophy@newlaneuniversity.com' &&
            !isUserInstructor(user) &&
            degrees.map(degree =>
              generateSubMenu(degree, history, user, pathItems, degree.courses),
            )} */}

            {/* {contentToRender}

          {user &&
            user.email !== 'contact@newlaneuniversity.com' &&
            user.email !== 'philosophy@newlaneuniversity.com' &&
            !isUserInstructor(user) &&
            (!user.enrolledDegrees || user.enrolledDegrees.length === 0) &&
            user.enrolledCourses &&
            user.enrolledCourses.map(course =>
              generateCourseSubMenu(course, user, openKeys),
            )}

          {user &&
            (user.email == 'contact@newlaneuniversity.com' ||
              user.email == 'philosophy@newlaneuniversity.com') &&
            !isUserInstructor(user) &&
            user.enrolledCourses &&
            user.enrolledCourses.map(course =>
              generateCourseSubMenu11(course, user, openKeys, pathItems),
            )} */}
          </Menu>
          {user.enrolledDegrees &&
          user.enrolledDegrees[0] &&
          user.enrolledDegrees[0].degree ? (
            <DegreeMenu degree={user.enrolledDegrees[0].degree} user={user} />
          ) : null}
        </>
      )}
    </Sider>
  );
};

export default Sidebar;
