import React, { FunctionComponent, useState } from 'react';
import { Input, Button, PageHeader, Row, Col, Divider, Form } from 'antd';
import { toast } from 'react-toastify';
import ObjectiveService from '@services/objectiveService';
import { useHistory } from 'react-router';
import { normalizeMedia } from '@components/forms/helpers';
import AssessmentForm from '@components/forms/AssessmentsForm';
import CourseService from '@services/courseService';
import AIIcon from '@assets/AI_with_notes.webp';
import ObjectiveMediaForm from './ObjectiveMediaForm';

toast.configure();

interface ObjectiveFormProps {
  objective: Partial<Objective>;
}

const ObjectiveForm: FunctionComponent<ObjectiveFormProps> = ({
  objective,
}) => {
  const history = useHistory();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [media, setMedia] = useState<ObjectiveMedia[]>(objective.media || []);
  const [assessments, setAssessments] = useState<Assessment[]>(
    objective.assessments || [],
  );
  const [objectiveName, setObjectiveName] = useState<any>(objective.name);
  const [assessmentsLoading, setAssessmentsLoading] = useState(false);
  const [form] = Form.useForm();
  const { setFieldsValue, validateFields } = form;

  const [assessmentsAILessons, setAssessmentsAILessons] = useState<any>([
    {
      question: '',
      answers: [
        {
          text: '',
          correct: false,
        },
      ],
    },
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    validateFields().then(values => {
      if (objective.id) {
        setUpdateLoading(true);

        const normalizedMedia = values.media
          ? normalizeMedia(values.media)
          : [];

        const savePromise =
          objective.id === -1
            ? ObjectiveService.create({
                ...values,
                media: normalizedMedia as ObjectiveMedia[],
              })
            : ObjectiveService.update(objective.id, {
                ...values,
                id: objective.id,
                media: normalizedMedia as ObjectiveMedia[],
              });

        savePromise
          .then(d => {
            toast.success('Update successful!');
            history.push(`/courses/${objective.courseId}/objectives/${d.id}`);
          })
          .catch(err => {
            toast.error(
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : 'Error saving objective.',
            );
            setUpdateLoading(false);
          });
      }
    });
  };

  const AILessonTopicaAsessments = async () => {
    try {
      if (objectiveName == '' || objectiveName == undefined) {
        return;
      }
      const sendData = {
        courseName: '',
        lessonName: '',
        topicName: objectiveName,
        userIdentifier: '',
      };
      setAssessmentsLoading(true);
      const LessonCourseTopicDes =
        await CourseService.getCourseLessonTopicAsessmentsAI(sendData);
      if (LessonCourseTopicDes.success) {
        setAssessmentsLoading(false);
        const storeaDta = [];
        for (
          let i = 0;
          i < LessonCourseTopicDes.data.completion.mcq_questions.length;
          i++
        ) {
          const storeaDtaAnswrs =
            LessonCourseTopicDes.data.completion.mcq_questions[i];

          // if (storeaDtaAnswrs.length > 0) {
          storeaDta.push({
            question: storeaDtaAnswrs.question,
            answers: [
              {
                id: -1,
                text: storeaDtaAnswrs.options[0].option.substr(
                  storeaDtaAnswrs.options[0].option.indexOf(' ') + 1,
                ),
                correct: storeaDtaAnswrs.options[0].is_correct,
              },
              {
                id: -1,
                text: storeaDtaAnswrs.options[1].option.substr(
                  storeaDtaAnswrs.options[0].option.indexOf(' ') + 1,
                ),
                correct: storeaDtaAnswrs.options[1].is_correct,
              },
              {
                id: -1,
                text: storeaDtaAnswrs.options[2].option.substr(
                  storeaDtaAnswrs.options[0].option.indexOf(' ') + 1,
                ),
                correct: storeaDtaAnswrs.options[2].is_correct,
              },
              {
                id: -1,
                text: storeaDtaAnswrs.options[3].option.substr(
                  storeaDtaAnswrs.options[0].option.indexOf(' ') + 1,
                ),
                correct: storeaDtaAnswrs.options[3].is_correct,
              },
            ],
          });
          // }
        }
        setAssessmentsAILessons(storeaDta);
      } else {
        // console.log('Get response else');
      }
    } catch (err) {
      // console.log('catch err', err);
    }
  };

  return (
    <Form onFinish={handleSubmit}>
      <Row gutter={24}>
        <Col span={24}>
          <PageHeader
            style={{
              background: '#fff',
              padding: '16px 16px 0',
            }}
            extra={[
              <Button
                key="save"
                type="primary"
                loading={updateLoading}
                onClick={handleSubmit}
              >
                Save
              </Button>,
            ]}
            title={
              <>
                <h2 style={{ margin: '8px 0' }}>
                  {objective.id === -1 ? 'Create ' : 'Edit '}Objective
                </h2>
              </>
            }
          />
          <div
            style={{
              background: '#fff',
              padding: '16px 16px',
            }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={objective.name}
            >
              <Input onChange={e => setObjectiveName(e.target.value)} />
            </Form.Item>

            <ObjectiveMediaForm media={media} setObjectiveMedia={setMedia} />

            <Divider />
            <span>
              {objectiveName && objectiveName != '' && (
                <Button
                  loading={assessmentsLoading}
                  className="ai-btn-objective-assessment"
                  type="primary"
                >
                  {' '}
                  {!assessmentsLoading && (
                    <img
                      style={{
                        height: '35px',
                        width: '35px',
                      }}
                      onClick={() => {
                        AILessonTopicaAsessments();
                      }}
                      src={AIIcon}
                    />
                  )}
                </Button>
              )}
            </span>
            <AssessmentForm
              assessmentsAILessons={assessmentsAILessons}
              assessments={assessments}
              setAssessments={setAssessments}
              setFieldsValue={setFieldsValue}
            />

            <Divider />

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={updateLoading}>
                Save
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ObjectiveForm;
