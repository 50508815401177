import React, { FunctionComponent, useState } from 'react';
import { Input, Button, Alert, Form } from 'antd';
import { Link } from 'react-router-dom';
import UserService from '@services/userService';
import { useQuery } from '@utils/routerHooks';

const ResetPasswordForm: FunctionComponent = ({}) => {
  const query = useQuery<{ token: string }>();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [error, setError] = useState<{ message: string }>();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<{ message: string }>();
  const [form] = Form.useForm();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields().then(values => {
      setLoading(true);
      UserService.resetPassword(query.token, values.password)
        .then(res => {
          setResponse(res);
          setLoading(false);
        })
        .catch(err => {
          err.response &&
            err.response.data &&
            err.response.data.message &&
            setError({
              message:
                err.response && err.response.data && err.response.data.message,
            });
          setLoading(false);
        });
    });
  };

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const checkPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  return (
    <div className="login">
      <section className="cover cover-page">
        <div className="cover-form-container">
          <div className="cover-form-wrap z-index">
            <section className="form-v1-container">
              <h2>Reset your password</h2>
              <p className="lead">Enter the new password and confirm it.</p>
              {error && (
                <Alert
                  message={error.message}
                  style={{ margin: '16px 0' }}
                  type="error"
                />
              )}
              {response && (
                <Alert
                  message={response.message}
                  style={{ margin: '16px 0' }}
                  type="success"
                />
              )}
              <Form form={form} onFinish={handleSubmit} className="form-v1">
                <Form.Item
                  label="Password"
                  hasFeedback
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  hasFeedback
                  name="confirm"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: checkPassword,
                    },
                  ]}
                >
                  <Input type="password" onBlur={handleConfirmBlur} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-cta btn-block"
                    disabled={loading}
                    loading={loading}
                  >
                    Reset password
                  </Button>
                </Form.Item>
              </Form>
              <p className="additional-info">
                <Link to="/login">Login</Link>
              </p>
              <p className="additional-info">
                Don't have an account yet? <Link to="/signup">Sign up</Link>
              </p>
            </section>
          </div>
        </div>
        <div className="cover-bg-img" />
      </section>
    </div>
  );
};

export default () => (
  <div>
    <ResetPasswordForm />
  </div>
);
