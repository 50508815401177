import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Input, Upload, Button, Divider, Form } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { toast } from 'react-toastify';
import ApiService from '@services/apiService';
import UserService from '@services/userService';
import { normalizeDocuments } from './helpers';

interface DocumentsFormProps {
  user: User;
  onSubmit: any;
}

const DocumentsForm: React.FC<DocumentsFormProps> = ({ user, onSubmit }) => {
  const [documents, setDocuments] = useState<UserDocument[]>([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [form] = Form.useForm();
  const { validateFields } = form;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    validateFields().then(values => {
      setUpdateLoading(true);

      const normalizedDocuments = values.documents
        ? normalizeDocuments(values.documents)
        : [];

      UserService.updateDocuments(user.id, normalizedDocuments)
        .then(d => {
          setUpdateLoading(false);
          toast.success('Update successful!');
          onSubmit();
        })
        .catch(() => {
          toast.error('Update failed!');
          setUpdateLoading(false);
        });
    });
  };

  const handleAddDocument = () => {
    setDocuments([
      ...documents,
      {
        id: -1,
        description: '',
      },
    ]);
  };

  const handleRemoveDocument = (index: number) => {
    const newData = documents;
    if (newData[index]) {
      newData.splice(index, 1);
    }

    setDocuments([...newData]);
  };

  const onUploadChange = (
    { fileList, file }: UploadChangeParam,
    index: number,
  ) => {
    if (fileList.length > 1) {
      fileList.splice(0, 1);
    }

    if (file.percent === 100) {
      const uploadedFile = file.response;

      const newData = documents;
      if (newData[index]) {
        newData[index].file = uploadedFile;
      }

      setDocuments([...newData]);
    }
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form onFinish={handleSubmit}>
      {documents.map((document, index) => (
        <>
          <Form.Item
            name={`documents[${index}].id`}
            initialValue={document.id}
            hidden
          >
            <Input hidden />
          </Form.Item>
          <Form.Item
            name={`documents[${index}].description`}
            label="Description"
            rules={[
              {
                required: true,
                message: 'This field is required!',
              },
            ]}
            initialValue={document.description}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name={`documents[${index}].file`}
            label="Example file"
            {...{
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              initialValue: document.file
                ? [
                    {
                      uid: document.file.id,
                      name: document.file.pid || document.file.url,
                      status: 'done',
                      ...document.file,
                    },
                  ]
                : [],
            }}
          >
            <Upload
              name="file"
              action={ApiService.getUrl('/files')}
              headers={ApiService.getHeaders()}
              listType="picture"
              onChange={e => onUploadChange(e, index)}
            >
              <Button>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <Button danger onClick={() => handleRemoveDocument(index)}>
            Delete document
          </Button>
          <Divider />
        </>
      ))}
      <Button type="primary" ghost onClick={() => handleAddDocument()}>
        Add new document
      </Button>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={updateLoading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DocumentsForm;
