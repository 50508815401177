export const normalizeProjects = (projects: any[]) =>
  projects.map((project: any) => {
    return {
      ...project,
      files: project.files.map((file: any) =>
        file.response ? file.response : file,
      ),
    };
  }) as Project[];
export const normalizeReadingts = (readings: any[]) =>
  readings.map((reading: any) => {
    return {
      ...reading,
      files: reading.files.map((file: any) =>
        file.response ? file.response : file,
      ),
    };
  }) as Reading[];

export const normalizeDocuments = (documents: any[]) =>
  documents.map((document: any) => {
    delete document.id;
    return {
      ...document,
      file:
        document.file && document.file[0] && document.file[0].response
          ? document.file[0].response
          : document.file[0],
    };
  }) as Partial<UserDocument>[];

export const normalizeMedia = (media: any[]) =>
  media.map((mediaItem: any) => {
    delete mediaItem.id;
    return {
      ...mediaItem,
      file:
        mediaItem.file && mediaItem.file[0] && mediaItem.file[0].response
          ? mediaItem.file[0].response
          : mediaItem.file[0],
    };
  }) as Partial<UserDocument>[];
